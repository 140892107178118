import React, { useState } from 'react'
import { doMoneyGun } from 'state/thunks/doMoneyGun'

export function MoneygunDropdown({
  isMainnet,
  connected,
  dispatch,
  account,
  signAndSubmitTransaction,
  isMSafe
}) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleAction = (coin) => {
    dispatch(
      doMoneyGun({
        address: account.address,
        signAndSub: signAndSubmitTransaction,
        coin: coin,
        isMSafe
      })
    )
    setIsOpen(false) // Close the dropdown after action
  }

  return (
    <div className="money-dropdown">
      {!isMainnet && connected && (
        <>
          <button
            className="money-dropdown-toggle sp-btn tertiary global-mr desk-only"
            onClick={toggleDropdown}>
            Faucet
          </button>
          <button
            className="money-dropdown-toggle sp-btn tertiary global-mr mobile-only"
            onClick={toggleDropdown}>
            $$$
          </button>
        </>
      )}
      {isOpen && !isMainnet && connected && (
        <div className="money-dropdown-menu">
          <button onClick={() => handleAction('CoinB')}>APT</button>
          <button onClick={() => handleAction('CoinA')}>zUSDC</button>
          <button onClick={() => handleAction('CoinC')}>thAPT</button>
          <button onClick={() => handleAction('StakedThalaAPT')}>sthAPT</button>
          <button onClick={() => handleAction('AmnisAPT')}>amAPT</button>
          <button onClick={() => handleAction('StakedAPT')}>stAPT</button>
          <button onClick={() => handleAction('USDTWormhole')}>whUSDT</button>
          <button onClick={() => handleAction('USDCWormhole')}>whUSDC</button>
          <button onClick={() => handleAction('USDTLZ')}>zUSDT</button>
          <button onClick={() => handleAction('WETH')}>zWETH</button>
          <button onClick={() => handleAction('WETHWormhole')}>whWETH</button>
          <button onClick={() => handleAction('THL')}>THL</button>
          <button onClick={() => handleAction('WBTC')}>zBTC</button>
          <button onClick={() => handleAction('aBTC')}>aBTC</button>
          <button onClick={() => handleAction('truAPT')}>truAPT</button>
          <button onClick={() => handleAction('USDT')}>USDT</button>
          <button onClick={() => window.open('https://faucet.circle.com/', '_blank').focus()}>
            USDC
          </button>
        </div>
      )}
    </div>
  )
}

export default MoneygunDropdown
