import usdcLogo from 'assets/logos/usdc-circle.svg'

export function NativeUSDCBanner() {
  return (
    <div className="native-usdc-banner">
      <div className="native-usdc-banner__left">
        <img src={usdcLogo} alt="USDC" />
        <div className="native-usdc-banner__content">
          <h3>Native USDC is live on Aptos!</h3>
          <p>Get some some boosted yield on native USDC</p>
        </div>
      </div>
    </div>
  )
}
