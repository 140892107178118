import usdcLogo from 'assets/logos/usdc-circle.svg'

export function USDCRedemptionBanner() {
  return (
    <div className="native-usdc-banner">
      <a
        href="https://app.panora.exchange/swap/aptos?pair=lzUSDC-USDC"
        target="_blank"
        rel="noreferrer">
        <div className="native-usdc-banner__left">
          <img src={usdcLogo} alt="USDC" />
          <div className="native-usdc-banner__content">
            <h3>Native USDC redemption is live on Aptos!</h3>
            <p>Get some USDC with 1 to 1 zUSDC redemption while supplies last</p>
          </div>
        </div>
      </a>
    </div>
  )
}
